const queryKeys = {
  // Booking
  getBookings: 'get-bookings',

  // User
  getCurrentUser: 'get-current-user',

  // Vehicle
  getVehicles: 'get-vehicles',

  // Invoice
};

export default queryKeys;
