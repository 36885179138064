exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-bookings-history-tsx": () => import("./../../../src/pages/account/bookings-history.tsx" /* webpackChunkName: "component---src-pages-account-bookings-history-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-upcoming-bookings-tsx": () => import("./../../../src/pages/account/upcoming-bookings.tsx" /* webpackChunkName: "component---src-pages-account-upcoming-bookings-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-vehicles-[id]-tsx": () => import("./../../../src/pages/vehicles/[id].tsx" /* webpackChunkName: "component---src-pages-vehicles-[id]-tsx" */),
  "component---src-pages-vehicles-index-tsx": () => import("./../../../src/pages/vehicles/index.tsx" /* webpackChunkName: "component---src-pages-vehicles-index-tsx" */)
}

