export const navigationLinks = [
  {
    text: 'home',
    href: '/',
    partiallyActive: false,
  },
  {
    text: 'about',
    href: '/about',
    partiallyActive: false,
  },
  {
    text: 'services',
    href: '/services',
    partiallyActive: false,
  },
  {
    text: 'contact',
    href: '/contact',
    partiallyActive: false,
  },
  {
    text: 'vehicles',
    href: '/vehicles',
    partiallyActive: false,
  },
];

export const features = [
  {
    title: 'Expert Diagnosis',
    description:
      'Our 3-year industry qualified engineers will thoroughly diagnose the problem with your LGP machinery, ensuring an accurate repair plan.',
  },
  {
    title: 'Transparent Communication',
    description:
      "We'll keep you informed throughout the repair process, explaining the issue and the recommended solution in clear, easy-to-understand terms.",
  },
  {
    title: 'Upfront Quotations',
    description:
      "You'll receive a transparent quote upfront, so you can make informed decisions about your repair.",
  },
  {
    title: 'Genuine Parts',
    description:
      'We use only high-quality, genuine parts to ensure long-lasting repairs and optimal performance for your LGP machinery.',
  },
  {
    title: 'Fast Turnaround Times',
    description:
      "We understand the need to get your equipment back in operation quickly. That's why we prioritize efficient service and strive for fast turnaround times.",
  },
  {
    title: '3-Year Industry Qualified Engineers',
    description:
      'Our team boasts extensive experience and expertise in handling all types of LGP machinery repairs.',
  },
  {
    title: 'Commitment to Quality',
    description:
      'We use only the best parts and equipment to ensure the quality and longevity of our repairs.',
  },
  {
    title: 'Transparent and Honest Service',
    description:
      'We believe in clear communication and upfront pricing, so you can trust us to deliver exceptional service.',
  },
  {
    title: 'Focus on Customer Satisfaction',
    description: 'We go the extra mile to ensure your complete satisfaction with our services.',
  },
];

export const contacts = [
  {
    icon: 'Phone',
    text: '+919999999999',
    link: 'tel:+916352115491',
  },
  {
    icon: 'Mail',
    text: 'hello@lgpms.co.uk',
    link: 'mailto:hello@lgpms.co.uk',
  },
  {
    icon: 'MapPin',
    text: 'Somewhere, United Kingdom',
    link: 'https://www.google.com/maps/dir//370+b,+GIDC+Rd,+opp.+MG+Motor,+Makarpura+GIDC,+Makarpura,+Vadodara,+Gujarat+390010/@22.253932,73.1024734,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x395fc5d5272bd1e1:0xeffe9fcabfbf62ed!2m2!1d73.1847874!2d22.2538857?entry=ttu',
  },
];

export const socials = [
  {
    icon: 'Facebook',
    text: '@lgpms',
    link: 'https://www.facebook.com/people/lgpms-Concepts-Pvt-Ltd/61557543657970/',
  },
  {
    icon: 'Instagram',
    text: '@lgpms',
    link: 'https://www.instagram.com/lgpmsconcepts/',
  },
  {
    icon: 'Linkedin',
    text: '@lgpms',
    link: 'https://instagram.com',
  },
  {
    icon: 'Twitter',
    text: '@lgpms',
    link: 'https://instagram.com',
  },
];
